/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS VARS */
:root {
  /* Colors */
  --main-color:      '';
  --secondary-color: '';
  --darkgray:        '';
  --lightgray:       '';

  /* Spacing */
  --navbar-width:   80px;
  --navbar-max-w:   320px;
}

/* FONTS */
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* TAILWIND */
@layer base{
    h1, h2, h3, h4, p{
        @apply font-main
    }
}

@layer components{
    /* Forms */
    .form label {
        @apply font-main font-bold default:text-xs text-main tracking-tight mb-1 ml-2 dark:text-secondary
    }
    .form input, .form select {
        @apply rounded-full border px-2 py-2 focus:border-main dark:focus:border-secondary outline-none font-main text-xs dark:bg-gray-900 dark:border-secondary dark:text-gray-400
    }
    /* Buttons */
    .button{
        @apply w-full rounded-full bg-main text-white p-1.5 font-main text-sm md:text-base shadow-lg dark:bg-secondary transition-all ease-in-out duration-300 hover:scale-95 hover:bg-secondary disabled:cursor-not-allowed disabled:bg-gray-500 disabled:hover:transform-none dark:disabled:bg-gray-700 
    }
    .button-secondary{
        @apply button bg-secondary
    }
    .button-outline{
        @apply button bg-transparent border border-main text-main hover:text-white hover:border-secondary
    }
    /* Radio Button  */
    .radio{
        @apply w-5 h-5 rounded-full border border-main checked:bg-main checked:border-main appearance-none checked:bg-[length:10px] cursor-pointer
    }

    /* Pills */
    .pill{
      @apply bg-white py-1 px-5 text-main font-main font-medium rounded-full border border-lightgray hover:bg-secondary hover:text-white hover:scale-95 hover:shadow transition-all ease-in duration-200 dark:bg-slate-800 dark:text-gray-500 dark:border-darkgray dark:hover:border-main
    }
    .pill-active{
      @apply pill bg-secondary text-white hover:transform-none dark:border-main
    }
    /* Selector */
    .venue-selector{
      @apply w-full py-2.5 rounded-[32px] text-secondary bg-white border font-bold  text-sm outline-none hover:focus:ring-1 ring-main dark:bg-gray-900 dark:border-secondary;
    }
    /* Sweet Alert */
    .swal2-input.input {
      @apply rounded-full border px-2 py-4 w-10/12 mx-auto focus:border-main dark:focus:border-secondary outline-none font-main text-xs dark:bg-gray-900 dark:border-secondary dark:text-gray-400
    }
}

/* DVM */
.d2m-main {
  border-radius: 32px;
} 
.dvm-v3d-main{
  border-radius: 15px;
  height: 100%!important;
}
.dvm-v3d-main.dvm-v3d-main-fullscreen {
  border-radius: 0;
}

/* DVM MOBILE */
@media (max-width:991px){
  .d2m-main {
    border-radius: 0;
  } 
}

/* CUSTOM RULES */

/* Scrollbar */
* {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  scrollbar-color: rgb(var(--main-color)) white;
  
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(var(--main-color));
  border-radius: 10px;
  border: 3px solid #ffffff;
}

/* Input Number remove Arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

#ScrollBox {

  &.masked {
    mask: linear-gradient(180deg,#0000,#000 5% 90%,#0000);
    -webkit-mask: linear-gradient(180deg,#0000,#000 5% 90%,#0000)
  }

  &.container{
    mask: linear-gradient(0deg,#0000,#000 20% 90%,#0000);
    -webkit-mask: linear-gradient(0deg,#0000,#000 20% 90%,#0000);
  }

}

/* Selects */
select {
  appearance: initial;
  text-align-last: center;
  -webkit-appearance: initial;
  cursor: pointer;
}

select.countrySelector {
  text-align-last: left;
}

/* Input Type Number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* Sweet Alert blur background on open */
body.swal2-shown > [aria-hidden='true'] {
  transition: 0.5s ease-in-out filter;
  filter: blur(.25em);
  -webkit-filter: blur(.25em);
}

/* Sweet Alert Options Modal Center Alignment */
div:where(.swal2-container) .swal2-radio {
  align-items: self-start!important;
}


/* Popover */
.popover {
  width: max-content;
  position: absolute;
  visibility: visible;
  top: 0;
  left: 0;
  z-index: 999;
  
  /* Hide Popover */
  &[hidden]{
    visibility: hidden!important;
  };

  /* Popover Arrow */
  & #arrow {
    position: absolute;
    /* background-color: rgb(var(--main-color));
    width: 10px;
    height: 10px; */
  }

  /* POPOVER DVM */
  &.popover-dvm {
    background-color: #000;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 5px;
    width: 250px;
    height: 150px;
    display: flex;
  }
  
}

/* Masked Behavior on Mobile */
@media (max-width:991px) {
  #ScrollBox.masked{
      mask: none;
      -webkit-mask: none;
  }
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgb(var(--secondary-color)) rgb(17 24 39);
  }

  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: rgb(17 24 39);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(var(--secondary-color));
    border-radius: 10px;
    border: 3px solid rgb(17 24 39);
  }

}